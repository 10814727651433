import React from 'react';
import { Helmet } from 'react-helmet-async';

const TermsOfUse: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Privacy</title>
      </Helmet>
      <div className="container pt-5">
        <div className="col-12">
          <div className="row">
            <h2>Terms of Use for Lengua.App</h2>
            <p>
              <strong>Effective Date: Nov 1, 2023</strong>
            </p>

            <h3>1. Acceptance of Terms</h3>
            <p>
              By accessing and using the Lengua.App ("App"), you agree to be bound by these Terms of Use ("Terms"). If you do not
              agree to these Terms, do not use the App.
            </p>

            <h3>2. Changes to Terms</h3>
            <p>
              We reserve the right to modify these Terms at any time. We will post the most current version of these Terms on our
              website with the effective date. Your continued use of the App after any changes signifies your acceptance of our
              updated Terms.
            </p>

            <h3>3. Use of the App</h3>
            <p>
              The App is intended for personal, non-commercial use. You may not use the App for illegal or unauthorized purposes.
              You are responsible for all your activity in connection with the App.
            </p>

            <h3>4. Intellectual Property</h3>
            <p>
              All content provided on the App, including but not limited to text, graphics, images, and software, is the property
              of Software Innovation Lab LLC or its content suppliers and protected by international copyright and intellectual
              property laws.
            </p>

            <h3>5. User Conduct</h3>
            <p>
              You agree not to use the App in a manner that is harmful, fraudulent, deceptive, threatening, harassing, defamatory,
              obscene, or otherwise objectionable.
            </p>

            <h3>6. Disclaimer of Warranties</h3>
            <p>
              The App is provided "as is" without any warranties of any kind, either express or implied, including but not limited
              to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
            </p>

            <h3>7. Limitation of Liability</h3>
            <p>
              Software Innovation Lab LLC shall not be liable for any indirect, incidental, special, consequential, or punitive
              damages arising out of or in connection with your use of the App.
            </p>

            <h3>8. Governing Law</h3>
            <p>
              These Terms shall be governed by and construed in accordance with the laws of Washington Sate, USA, without regard
              to its conflict of law provisions.
            </p>

            <h3>9. Contact Information</h3>
            <p>
              For any questions or concerns regarding these Terms, please contact us at{' '}
              <a href="mailto:support@lengua.app">Email Support: support@lengua.app</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
