import React from 'react';
import { Helmet } from 'react-helmet-async';

const HomePage: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Learn Language</title>
      </Helmet>
      <div className="container">
        <div className="col-12 px-4">
          <div className="row flex-lg-row-reverse align-items-center">
            <div className="col-12 col-lg-5 text-center text-md-start">
              <img
                srcSet={`${process.env.PUBLIC_URL}/images/people-talking1_low.jpg 300w,
                ${process.env.PUBLIC_URL}/images/people-talking1_medium.jpg 600w,
                ${process.env.PUBLIC_URL}/images/people-talking1_high.jpg 1200w`}
                sizes="(max-width: 600px) 300px,
               (max-width: 1200px) 600px,
               1200px"
                src={`${process.env.PUBLIC_URL}/images/people-talking1_high.jpg`}
                className="d-block mx-lg-auto img-fluid"
                alt=""
                width="700"
                height="500"
                loading="lazy"
              />
            </div>
            <div className="col-lg-7 text-center text-md-start">
              <h1 className="display-5 fw-bold lh-1 mb-3 mt-3 mt-md-0">Welcome to a new way of learning languages</h1>
              <p className="lead">
                Master language fluency efficiently with our innovative tool, guiding serious learners through contextual
                vocabulary acquisition, mimicking the natural process by which children grasp language intricacies.
              </p>
              <div className="mt-5">
                <h4>Download the App</h4>
                <a
                  href="https://apps.apple.com/eg/app/lengua-learn-spanish/id6469734069"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="d-inline-block mb-3"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg`}
                    alt="Download on the App Store"
                    style={{ height: '60px', maxWidth: '100%' }}
                  />
                </a>
                <p className="lead mt-5">
                  <strong>Coming Soon: </strong>
                  Android version
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
