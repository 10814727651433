import { Helmet } from 'react-helmet-async';

import SentenceSetForm from '../components/SentenceSetForm';

const SetManagementPage = () => {
  return (
    <div className="container mt-4 mb-5">
      <Helmet>
        <title>Manage Sets</title>
      </Helmet>
      <h3>Manage Sets:</h3>

      <div className="row">
        <div className="col">
          <SentenceSetForm />
        </div>
      </div>
    </div>
  );
};

export default SetManagementPage;
