import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';

import PracticeSync from '../components/PracticeSync';
import { upgradeStore } from '../store/coreSlice';
import { RootState } from '../store/store';

const ArchivePage = () => {
  return (
    <div className="container mt-4 mb-5">
      <Helmet>
        <title>Archive</title>
      </Helmet>

      <PracticeSync />
    </div>
  );
};

export default ArchivePage;
