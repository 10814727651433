import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
};

export type AddMetaInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
};

export type AdminSettings = {
  __typename?: 'AdminSettings';
  data: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type AdminSettingsInput = {
  data: Scalars['JSON']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type AppSettings = {
  __typename?: 'AppSettings';
  availableLangs: Array<Scalars['String']['output']>;
  contentFiles: Array<Scalars['String']['output']>;
  userSettings?: Maybe<UserSettings>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type Choice = {
  __typename?: 'Choice';
  content?: Maybe<Scalars['String']['output']>;
  finishReason?: Maybe<Scalars['String']['output']>;
  tokensUsed?: Maybe<Scalars['Int']['output']>;
};

export type CreateFeedbackInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type DeleteMetaSynonymsInput = {
  id: Scalars['ID']['input'];
  synonyms: Array<SynonymDeletionInput>;
};

export type DeleteSentenceInput = {
  where?: InputMaybe<Scalars['JSON']['input']>;
};

export type Feedback = {
  __typename?: 'Feedback';
  createDate: Scalars['String']['output'];
  creator?: Maybe<User>;
  data?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  isProcessed: Scalars['Boolean']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  process?: Maybe<Scalars['JSON']['output']>;
  type: Scalars['String']['output'];
  updateDate: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addMetaSentence?: Maybe<Scalars['Boolean']['output']>;
  anonymizeUser?: Maybe<User>;
  createFeedback?: Maybe<Scalars['Boolean']['output']>;
  deleteAdminSettings?: Maybe<Scalars['Boolean']['output']>;
  deleteFeedback?: Maybe<Scalars['Boolean']['output']>;
  deleteMetaSynonyms?: Maybe<Scalars['Boolean']['output']>;
  deleteSentence?: Maybe<Scalars['Boolean']['output']>;
  deleteSentenceSet: Scalars['ID']['output'];
  deleteTranslation?: Maybe<Translation>;
  deleteUser: User;
  insertAdminSettings?: Maybe<AdminSettings>;
  login: User;
  syncSettings?: Maybe<AppSettings>;
  synthesizeVoice: SynthesizeVoiceResponse;
  translate: Array<Choice>;
  updateFeedback?: Maybe<Scalars['Boolean']['output']>;
  updateSentenceSetOrder?: Maybe<Scalars['Boolean']['output']>;
  updateUser: User;
  uploadPractice: Array<Scalars['String']['output']>;
  upsertAdminSettings?: Maybe<AdminSettings>;
  upsertSentence?: Maybe<Scalars['Boolean']['output']>;
  upsertSentenceSet: SentenceSet;
  upsertTranslations?: Maybe<Scalars['Boolean']['output']>;
};


export type MutationAddMetaSentenceArgs = {
  inputs: Array<AddMetaInput>;
};


export type MutationAnonymizeUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput;
};


export type MutationDeleteAdminSettingsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteFeedbackArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMetaSynonymsArgs = {
  input?: InputMaybe<DeleteMetaSynonymsInput>;
};


export type MutationDeleteSentenceArgs = {
  input?: InputMaybe<DeleteSentenceInput>;
};


export type MutationDeleteSentenceSetArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTranslationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationInsertAdminSettingsArgs = {
  input: AdminSettingsInput;
};


export type MutationLoginArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  source: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSyncSettingsArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  transLangs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userSettings?: InputMaybe<UserSettingsInput>;
};


export type MutationSynthesizeVoiceArgs = {
  input: SynthesizeVoiceInput;
};


export type MutationTranslateArgs = {
  input: TranslatePromptInput;
};


export type MutationUpdateFeedbackArgs = {
  input: UpdateFeedbackInput;
};


export type MutationUpdateSentenceSetOrderArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationUpdateUserArgs = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  lastName: Scalars['String']['input'];
};


export type MutationUploadPracticeArgs = {
  input: Array<UploadPracticeInput>;
};


export type MutationUpsertAdminSettingsArgs = {
  input: AdminSettingsInput;
};


export type MutationUpsertSentenceArgs = {
  inputs: Array<SentenceUpsertInput>;
};


export type MutationUpsertSentenceSetArgs = {
  input: SentenceSetUpsertInput;
};


export type MutationUpsertTranslationsArgs = {
  inputs: Array<TranslationInput>;
};

export type Practice = {
  __typename?: 'Practice';
  data: Scalars['JSON']['output'];
  date: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  schema?: Maybe<Scalars['Float']['output']>;
};

export type Query = {
  __typename?: 'Query';
  appSettings?: Maybe<AppSettings>;
  getAdminSettings: Array<AdminSettings>;
  getAdminSettingsById?: Maybe<AdminSettings>;
  getAdminSettingsByType: Array<AdminSettings>;
  getAllSentenceSets: Array<SentenceSet>;
  getAllUnprocessedFeedbacks?: Maybe<Array<Feedback>>;
  getMissingVoices: Array<Sentence>;
  getSentenceSet?: Maybe<SentenceSet>;
  practices: Array<Practice>;
  sentence?: Maybe<Sentence>;
  sentences?: Maybe<Array<Sentence>>;
  sentencesWithSynonyms?: Maybe<Array<Sentence>>;
  translation?: Maybe<Translation>;
  translations?: Maybe<Array<Translation>>;
  user?: Maybe<User>;
};


export type QueryAppSettingsArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  transLangs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGetAdminSettingsByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAdminSettingsByTypeArgs = {
  type: Scalars['String']['input'];
};


export type QueryGetAllSentenceSetsArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  transLangs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGetMissingVoicesArgs = {
  override?: InputMaybe<Scalars['Boolean']['input']>;
  setID: Scalars['String']['input'];
};


export type QueryGetSentenceSetArgs = {
  id: Scalars['ID']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
  transLangs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QuerySentenceArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySentencesArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sentenceSetId?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
  tags?: InputMaybe<Scalars['String']['input']>;
  transLangs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['JSON']['input']>;
};


export type QuerySentencesWithSynonymsArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sentenceSetId?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
  tags?: InputMaybe<Scalars['String']['input']>;
  transLangs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryTranslationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTranslationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sentenceId: Scalars['ID']['input'];
  sort?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
  tags?: InputMaybe<Scalars['String']['input']>;
  transLang: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryUserArgs = {
  is: Scalars['String']['input'];
};

export type Sentence = {
  __typename?: 'Sentence';
  creator?: Maybe<User>;
  id: Scalars['ID']['output'];
  lang: Scalars['String']['output'];
  meta?: Maybe<Scalars['JSON']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  schema?: Maybe<Scalars['Float']['output']>;
  tags?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  translations: Array<Translation>;
  type: Scalars['String']['output'];
};

export type SentenceInput = {
  id: Scalars['String']['input'];
  lang: Scalars['String']['input'];
  meta?: InputMaybe<Scalars['JSON']['input']>;
  text: Scalars['String']['input'];
};

export type SentenceSet = {
  __typename?: 'SentenceSet';
  createDate?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<User>;
  id: Scalars['ID']['output'];
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  lang?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  schema?: Maybe<Scalars['Float']['output']>;
  sentences?: Maybe<Array<Sentence>>;
  type: Scalars['String']['output'];
  updateDate?: Maybe<Scalars['String']['output']>;
};

export type SentenceSetSettings = {
  __typename?: 'SentenceSetSettings';
  lastUpdated: Scalars['Int']['output'];
  maxSentencesInSet: Scalars['Int']['output'];
  numCorrectToPass: Scalars['Int']['output'];
};

export type SentenceSetSettingsInput = {
  lastUpdated: Scalars['Int']['input'];
  maxSentencesInSet: Scalars['Int']['input'];
  numCorrectToPass: Scalars['Int']['input'];
  setId: Scalars['String']['input'];
};

export type SentenceSetSettingsMap = {
  __typename?: 'SentenceSetSettingsMap';
  setId: Scalars['String']['output'];
  settings: SentenceSetSettings;
};

export type SentenceSetUpsertInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isPublic: Scalars['Boolean']['input'];
  lang: Scalars['String']['input'];
  meta?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  schema: Scalars['Float']['input'];
  type: Scalars['String']['input'];
};

export type SentenceUpsertInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  lang: Scalars['String']['input'];
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  schema: Scalars['Float']['input'];
  setId: Scalars['ID']['input'];
  tags?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type SynonymDeletionInput = {
  key: Scalars['String']['input'];
  valuesToDelete?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SynthesizeVoiceInput = {
  lang: Scalars['String']['input'];
  override?: InputMaybe<Scalars['Boolean']['input']>;
  sentences: Array<SentenceInput>;
  setID: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type SynthesizeVoiceResponse = {
  __typename?: 'SynthesizeVoiceResponse';
  success: Scalars['Boolean']['output'];
};

export type TranslatePromptInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  values: Scalars['JSON']['input'];
};

export type Translation = {
  __typename?: 'Translation';
  creator?: Maybe<User>;
  id: Scalars['ID']['output'];
  lang: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  schema?: Maybe<Scalars['Float']['output']>;
  tags?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export type TranslationInput = {
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  sentenceId: Scalars['ID']['input'];
  tags?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  transLang: Scalars['String']['input'];
};

export type UpdateFeedbackInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UploadPracticeInput = {
  data: Scalars['JSON']['input'];
  date: Scalars['Int']['input'];
  schema: Scalars['Float']['input'];
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jwt?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  sentenceSetSettings: Array<SentenceSetSettingsMap>;
};

export type UserSettingsInput = {
  sentenceSetSettings: Array<SentenceSetSettingsInput>;
};

export type AdminSettingsFieldsFragment = { __typename?: 'AdminSettings', id: string, name: string, type: string, data: any };

export type GetAdminSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminSettingsQuery = { __typename?: 'Query', getAdminSettings: Array<{ __typename?: 'AdminSettings', id: string, name: string, type: string, data: any }> };

export type GetAdminSettingsByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetAdminSettingsByIdQuery = { __typename?: 'Query', getAdminSettingsById?: { __typename?: 'AdminSettings', id: string, name: string, type: string, data: any } | null };

export type UpsertAdminSettingsMutationVariables = Exact<{
  input: AdminSettingsInput;
}>;


export type UpsertAdminSettingsMutation = { __typename?: 'Mutation', upsertAdminSettings?: { __typename?: 'AdminSettings', id: string, name: string, type: string, data: any } | null };

export type DeleteAdminSettingsMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteAdminSettingsMutation = { __typename?: 'Mutation', deleteAdminSettings?: boolean | null };

export type GetAllUnprocessedFeedbacksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUnprocessedFeedbacksQuery = { __typename?: 'Query', getAllUnprocessedFeedbacks?: Array<{ __typename?: 'Feedback', id: string, type: string, data?: any | null, process?: any | null, notes?: string | null, isProcessed: boolean, createDate: string, updateDate: string, creator?: { __typename?: 'User', id: string } | null }> | null };

export type CreateFeedbackMutationVariables = Exact<{
  input: CreateFeedbackInput;
}>;


export type CreateFeedbackMutation = { __typename?: 'Mutation', createFeedback?: boolean | null };

export type UpdateFeedbackMutationVariables = Exact<{
  input: UpdateFeedbackInput;
}>;


export type UpdateFeedbackMutation = { __typename?: 'Mutation', updateFeedback?: boolean | null };

export type DeleteFeedbackMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteFeedbackMutation = { __typename?: 'Mutation', deleteFeedback?: boolean | null };

export type TranslateMutationVariables = Exact<{
  input: TranslatePromptInput;
}>;


export type TranslateMutation = { __typename?: 'Mutation', translate: Array<{ __typename?: 'Choice', content?: string | null, finishReason?: string | null, tokensUsed?: number | null }> };

export type UploadPracticeMutationVariables = Exact<{
  input: Array<UploadPracticeInput> | UploadPracticeInput;
}>;


export type UploadPracticeMutation = { __typename?: 'Mutation', uploadPractice: Array<string> };

export type PracticesQueryVariables = Exact<{ [key: string]: never; }>;


export type PracticesQuery = { __typename?: 'Query', practices: Array<{ __typename?: 'Practice', id: string, date: number, data: any }> };

export type SentenceSetOneFragment = { __typename?: 'SentenceSet', id: string, name: string, notes?: string | null, meta?: any | null, type: string, isPublic?: boolean | null, lang?: string | null, sentences?: Array<{ __typename?: 'Sentence', id: string, lang: string, text: string, type: string, meta?: any | null, translations: Array<{ __typename?: 'Translation', id: string, lang: string, text: string }> }> | null };

export type GetAllSentenceSetsQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']['input']>;
  transLangs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type GetAllSentenceSetsQuery = { __typename?: 'Query', getAllSentenceSets: Array<{ __typename?: 'SentenceSet', id: string, name: string, notes?: string | null, meta?: any | null, type: string, isPublic?: boolean | null, lang?: string | null, sentences?: Array<{ __typename?: 'Sentence', id: string, lang: string, text: string, type: string, meta?: any | null, translations: Array<{ __typename?: 'Translation', id: string, lang: string, text: string }> }> | null }> };

export type UpsertSentenceSetMutationVariables = Exact<{
  input: SentenceSetUpsertInput;
}>;


export type UpsertSentenceSetMutation = { __typename?: 'Mutation', upsertSentenceSet: { __typename?: 'SentenceSet', id: string, name: string, lang?: string | null, notes?: string | null, type: string, meta?: any | null, isPublic?: boolean | null } };

export type DeleteSentenceSetMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteSentenceSetMutation = { __typename?: 'Mutation', deleteSentenceSet: string };

export type UpdateSentenceSetOrderMutationVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type UpdateSentenceSetOrderMutation = { __typename?: 'Mutation', updateSentenceSetOrder?: boolean | null };

export type SentencesWithSynonymsQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']['input']>;
  transLangs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type SentencesWithSynonymsQuery = { __typename?: 'Query', sentencesWithSynonyms?: Array<{ __typename?: 'Sentence', id: string, lang: string, text: string, type: string, tags?: string | null, meta?: any | null, translations: Array<{ __typename?: 'Translation', id: string, lang: string, text: string }>, creator?: { __typename?: 'User', id: string, name?: string | null } | null }> | null };

export type DeleteSentenceMutationVariables = Exact<{
  input: DeleteSentenceInput;
}>;


export type DeleteSentenceMutation = { __typename?: 'Mutation', deleteSentence?: boolean | null };

export type DeleteMetaSynonymsMutationVariables = Exact<{
  input: DeleteMetaSynonymsInput;
}>;


export type DeleteMetaSynonymsMutation = { __typename?: 'Mutation', deleteMetaSynonyms?: boolean | null };

export type AddMetaSentenceMutationVariables = Exact<{
  inputs: Array<AddMetaInput> | AddMetaInput;
}>;


export type AddMetaSentenceMutation = { __typename?: 'Mutation', addMetaSentence?: boolean | null };

export type UpsertSentenceMutationVariables = Exact<{
  inputs: Array<SentenceUpsertInput> | SentenceUpsertInput;
}>;


export type UpsertSentenceMutation = { __typename?: 'Mutation', upsertSentence?: boolean | null };

export type UpsertTranslationsMutationVariables = Exact<{
  inputs: Array<TranslationInput> | TranslationInput;
}>;


export type UpsertTranslationsMutation = { __typename?: 'Mutation', upsertTranslations?: boolean | null };

export type GetMissingVoicesQueryVariables = Exact<{
  setID: Scalars['String']['input'];
  override?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetMissingVoicesQuery = { __typename?: 'Query', getMissingVoices: Array<{ __typename?: 'Sentence', id: string, text: string, lang: string, meta?: any | null }> };

export type SynthesizeVoiceMutationVariables = Exact<{
  input: SynthesizeVoiceInput;
}>;


export type SynthesizeVoiceMutation = { __typename?: 'Mutation', synthesizeVoice: { __typename?: 'SynthesizeVoiceResponse', success: boolean } };

export type LoginMutationVariables = Exact<{
  token: Scalars['String']['input'];
  source: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'User', id: string, name?: string | null, email?: string | null, avatar?: string | null, role?: string | null, jwt?: string | null } };

export const AdminSettingsFieldsFragmentDoc = gql`
    fragment AdminSettingsFields on AdminSettings {
  id
  name
  type
  data
}
    `;
export const SentenceSetOneFragmentDoc = gql`
    fragment SentenceSetOne on SentenceSet {
  id
  name
  notes
  meta
  type
  isPublic
  lang
  sentences {
    id
    lang
    text
    type
    meta
    translations {
      id
      lang
      text
    }
  }
}
    `;
export const GetAdminSettingsDocument = gql`
    query GetAdminSettings {
  getAdminSettings {
    ...AdminSettingsFields
  }
}
    ${AdminSettingsFieldsFragmentDoc}`;

/**
 * __useGetAdminSettingsQuery__
 *
 * To run a query within a React component, call `useGetAdminSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdminSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>(GetAdminSettingsDocument, options);
      }
export function useGetAdminSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>(GetAdminSettingsDocument, options);
        }
export function useGetAdminSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>(GetAdminSettingsDocument, options);
        }
export type GetAdminSettingsQueryHookResult = ReturnType<typeof useGetAdminSettingsQuery>;
export type GetAdminSettingsLazyQueryHookResult = ReturnType<typeof useGetAdminSettingsLazyQuery>;
export type GetAdminSettingsSuspenseQueryHookResult = ReturnType<typeof useGetAdminSettingsSuspenseQuery>;
export type GetAdminSettingsQueryResult = Apollo.QueryResult<GetAdminSettingsQuery, GetAdminSettingsQueryVariables>;
export const GetAdminSettingsByIdDocument = gql`
    query GetAdminSettingsById($id: ID!) {
  getAdminSettingsById(id: $id) {
    ...AdminSettingsFields
  }
}
    ${AdminSettingsFieldsFragmentDoc}`;

/**
 * __useGetAdminSettingsByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminSettingsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminSettingsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminSettingsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminSettingsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>(GetAdminSettingsByIdDocument, options);
      }
export function useGetAdminSettingsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>(GetAdminSettingsByIdDocument, options);
        }
export function useGetAdminSettingsByIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>(GetAdminSettingsByIdDocument, options);
        }
export type GetAdminSettingsByIdQueryHookResult = ReturnType<typeof useGetAdminSettingsByIdQuery>;
export type GetAdminSettingsByIdLazyQueryHookResult = ReturnType<typeof useGetAdminSettingsByIdLazyQuery>;
export type GetAdminSettingsByIdSuspenseQueryHookResult = ReturnType<typeof useGetAdminSettingsByIdSuspenseQuery>;
export type GetAdminSettingsByIdQueryResult = Apollo.QueryResult<GetAdminSettingsByIdQuery, GetAdminSettingsByIdQueryVariables>;
export const UpsertAdminSettingsDocument = gql`
    mutation UpsertAdminSettings($input: AdminSettingsInput!) {
  upsertAdminSettings(input: $input) {
    ...AdminSettingsFields
  }
}
    ${AdminSettingsFieldsFragmentDoc}`;
export type UpsertAdminSettingsMutationFn = Apollo.MutationFunction<UpsertAdminSettingsMutation, UpsertAdminSettingsMutationVariables>;

/**
 * __useUpsertAdminSettingsMutation__
 *
 * To run a mutation, you first call `useUpsertAdminSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAdminSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAdminSettingsMutation, { data, loading, error }] = useUpsertAdminSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertAdminSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertAdminSettingsMutation, UpsertAdminSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertAdminSettingsMutation, UpsertAdminSettingsMutationVariables>(UpsertAdminSettingsDocument, options);
      }
export type UpsertAdminSettingsMutationHookResult = ReturnType<typeof useUpsertAdminSettingsMutation>;
export type UpsertAdminSettingsMutationResult = Apollo.MutationResult<UpsertAdminSettingsMutation>;
export type UpsertAdminSettingsMutationOptions = Apollo.BaseMutationOptions<UpsertAdminSettingsMutation, UpsertAdminSettingsMutationVariables>;
export const DeleteAdminSettingsDocument = gql`
    mutation DeleteAdminSettings($id: ID!) {
  deleteAdminSettings(id: $id)
}
    `;
export type DeleteAdminSettingsMutationFn = Apollo.MutationFunction<DeleteAdminSettingsMutation, DeleteAdminSettingsMutationVariables>;

/**
 * __useDeleteAdminSettingsMutation__
 *
 * To run a mutation, you first call `useDeleteAdminSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminSettingsMutation, { data, loading, error }] = useDeleteAdminSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAdminSettingsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdminSettingsMutation, DeleteAdminSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAdminSettingsMutation, DeleteAdminSettingsMutationVariables>(DeleteAdminSettingsDocument, options);
      }
export type DeleteAdminSettingsMutationHookResult = ReturnType<typeof useDeleteAdminSettingsMutation>;
export type DeleteAdminSettingsMutationResult = Apollo.MutationResult<DeleteAdminSettingsMutation>;
export type DeleteAdminSettingsMutationOptions = Apollo.BaseMutationOptions<DeleteAdminSettingsMutation, DeleteAdminSettingsMutationVariables>;
export const GetAllUnprocessedFeedbacksDocument = gql`
    query GetAllUnprocessedFeedbacks {
  getAllUnprocessedFeedbacks {
    id
    type
    data
    process
    notes
    isProcessed
    createDate
    updateDate
    creator {
      id
    }
  }
}
    `;

/**
 * __useGetAllUnprocessedFeedbacksQuery__
 *
 * To run a query within a React component, call `useGetAllUnprocessedFeedbacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUnprocessedFeedbacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUnprocessedFeedbacksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUnprocessedFeedbacksQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUnprocessedFeedbacksQuery, GetAllUnprocessedFeedbacksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUnprocessedFeedbacksQuery, GetAllUnprocessedFeedbacksQueryVariables>(GetAllUnprocessedFeedbacksDocument, options);
      }
export function useGetAllUnprocessedFeedbacksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUnprocessedFeedbacksQuery, GetAllUnprocessedFeedbacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUnprocessedFeedbacksQuery, GetAllUnprocessedFeedbacksQueryVariables>(GetAllUnprocessedFeedbacksDocument, options);
        }
export function useGetAllUnprocessedFeedbacksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllUnprocessedFeedbacksQuery, GetAllUnprocessedFeedbacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllUnprocessedFeedbacksQuery, GetAllUnprocessedFeedbacksQueryVariables>(GetAllUnprocessedFeedbacksDocument, options);
        }
export type GetAllUnprocessedFeedbacksQueryHookResult = ReturnType<typeof useGetAllUnprocessedFeedbacksQuery>;
export type GetAllUnprocessedFeedbacksLazyQueryHookResult = ReturnType<typeof useGetAllUnprocessedFeedbacksLazyQuery>;
export type GetAllUnprocessedFeedbacksSuspenseQueryHookResult = ReturnType<typeof useGetAllUnprocessedFeedbacksSuspenseQuery>;
export type GetAllUnprocessedFeedbacksQueryResult = Apollo.QueryResult<GetAllUnprocessedFeedbacksQuery, GetAllUnprocessedFeedbacksQueryVariables>;
export const CreateFeedbackDocument = gql`
    mutation CreateFeedback($input: CreateFeedbackInput!) {
  createFeedback(input: $input)
}
    `;
export type CreateFeedbackMutationFn = Apollo.MutationFunction<CreateFeedbackMutation, CreateFeedbackMutationVariables>;

/**
 * __useCreateFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackMutation, { data, loading, error }] = useCreateFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFeedbackMutation, CreateFeedbackMutationVariables>(CreateFeedbackDocument, options);
      }
export type CreateFeedbackMutationHookResult = ReturnType<typeof useCreateFeedbackMutation>;
export type CreateFeedbackMutationResult = Apollo.MutationResult<CreateFeedbackMutation>;
export type CreateFeedbackMutationOptions = Apollo.BaseMutationOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>;
export const UpdateFeedbackDocument = gql`
    mutation UpdateFeedback($input: UpdateFeedbackInput!) {
  updateFeedback(input: $input)
}
    `;
export type UpdateFeedbackMutationFn = Apollo.MutationFunction<UpdateFeedbackMutation, UpdateFeedbackMutationVariables>;

/**
 * __useUpdateFeedbackMutation__
 *
 * To run a mutation, you first call `useUpdateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedbackMutation, { data, loading, error }] = useUpdateFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeedbackMutation, UpdateFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeedbackMutation, UpdateFeedbackMutationVariables>(UpdateFeedbackDocument, options);
      }
export type UpdateFeedbackMutationHookResult = ReturnType<typeof useUpdateFeedbackMutation>;
export type UpdateFeedbackMutationResult = Apollo.MutationResult<UpdateFeedbackMutation>;
export type UpdateFeedbackMutationOptions = Apollo.BaseMutationOptions<UpdateFeedbackMutation, UpdateFeedbackMutationVariables>;
export const DeleteFeedbackDocument = gql`
    mutation DeleteFeedback($id: ID!) {
  deleteFeedback(id: $id)
}
    `;
export type DeleteFeedbackMutationFn = Apollo.MutationFunction<DeleteFeedbackMutation, DeleteFeedbackMutationVariables>;

/**
 * __useDeleteFeedbackMutation__
 *
 * To run a mutation, you first call `useDeleteFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedbackMutation, { data, loading, error }] = useDeleteFeedbackMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeedbackMutation, DeleteFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeedbackMutation, DeleteFeedbackMutationVariables>(DeleteFeedbackDocument, options);
      }
export type DeleteFeedbackMutationHookResult = ReturnType<typeof useDeleteFeedbackMutation>;
export type DeleteFeedbackMutationResult = Apollo.MutationResult<DeleteFeedbackMutation>;
export type DeleteFeedbackMutationOptions = Apollo.BaseMutationOptions<DeleteFeedbackMutation, DeleteFeedbackMutationVariables>;
export const TranslateDocument = gql`
    mutation Translate($input: TranslatePromptInput!) {
  translate(input: $input) {
    content
    finishReason
    tokensUsed
  }
}
    `;
export type TranslateMutationFn = Apollo.MutationFunction<TranslateMutation, TranslateMutationVariables>;

/**
 * __useTranslateMutation__
 *
 * To run a mutation, you first call `useTranslateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTranslateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [translateMutation, { data, loading, error }] = useTranslateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTranslateMutation(baseOptions?: Apollo.MutationHookOptions<TranslateMutation, TranslateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TranslateMutation, TranslateMutationVariables>(TranslateDocument, options);
      }
export type TranslateMutationHookResult = ReturnType<typeof useTranslateMutation>;
export type TranslateMutationResult = Apollo.MutationResult<TranslateMutation>;
export type TranslateMutationOptions = Apollo.BaseMutationOptions<TranslateMutation, TranslateMutationVariables>;
export const UploadPracticeDocument = gql`
    mutation UploadPractice($input: [UploadPracticeInput!]!) {
  uploadPractice(input: $input)
}
    `;
export type UploadPracticeMutationFn = Apollo.MutationFunction<UploadPracticeMutation, UploadPracticeMutationVariables>;

/**
 * __useUploadPracticeMutation__
 *
 * To run a mutation, you first call `useUploadPracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPracticeMutation, { data, loading, error }] = useUploadPracticeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPracticeMutation(baseOptions?: Apollo.MutationHookOptions<UploadPracticeMutation, UploadPracticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadPracticeMutation, UploadPracticeMutationVariables>(UploadPracticeDocument, options);
      }
export type UploadPracticeMutationHookResult = ReturnType<typeof useUploadPracticeMutation>;
export type UploadPracticeMutationResult = Apollo.MutationResult<UploadPracticeMutation>;
export type UploadPracticeMutationOptions = Apollo.BaseMutationOptions<UploadPracticeMutation, UploadPracticeMutationVariables>;
export const PracticesDocument = gql`
    query Practices {
  practices {
    id
    date
    data
  }
}
    `;

/**
 * __usePracticesQuery__
 *
 * To run a query within a React component, call `usePracticesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePracticesQuery(baseOptions?: Apollo.QueryHookOptions<PracticesQuery, PracticesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PracticesQuery, PracticesQueryVariables>(PracticesDocument, options);
      }
export function usePracticesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PracticesQuery, PracticesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PracticesQuery, PracticesQueryVariables>(PracticesDocument, options);
        }
export function usePracticesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PracticesQuery, PracticesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PracticesQuery, PracticesQueryVariables>(PracticesDocument, options);
        }
export type PracticesQueryHookResult = ReturnType<typeof usePracticesQuery>;
export type PracticesLazyQueryHookResult = ReturnType<typeof usePracticesLazyQuery>;
export type PracticesSuspenseQueryHookResult = ReturnType<typeof usePracticesSuspenseQuery>;
export type PracticesQueryResult = Apollo.QueryResult<PracticesQuery, PracticesQueryVariables>;
export const GetAllSentenceSetsDocument = gql`
    query GetAllSentenceSets($lang: String, $transLangs: [String]) {
  getAllSentenceSets(lang: $lang, transLangs: $transLangs) {
    ...SentenceSetOne
  }
}
    ${SentenceSetOneFragmentDoc}`;

/**
 * __useGetAllSentenceSetsQuery__
 *
 * To run a query within a React component, call `useGetAllSentenceSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSentenceSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSentenceSetsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      transLangs: // value for 'transLangs'
 *   },
 * });
 */
export function useGetAllSentenceSetsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSentenceSetsQuery, GetAllSentenceSetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSentenceSetsQuery, GetAllSentenceSetsQueryVariables>(GetAllSentenceSetsDocument, options);
      }
export function useGetAllSentenceSetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSentenceSetsQuery, GetAllSentenceSetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSentenceSetsQuery, GetAllSentenceSetsQueryVariables>(GetAllSentenceSetsDocument, options);
        }
export function useGetAllSentenceSetsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllSentenceSetsQuery, GetAllSentenceSetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllSentenceSetsQuery, GetAllSentenceSetsQueryVariables>(GetAllSentenceSetsDocument, options);
        }
export type GetAllSentenceSetsQueryHookResult = ReturnType<typeof useGetAllSentenceSetsQuery>;
export type GetAllSentenceSetsLazyQueryHookResult = ReturnType<typeof useGetAllSentenceSetsLazyQuery>;
export type GetAllSentenceSetsSuspenseQueryHookResult = ReturnType<typeof useGetAllSentenceSetsSuspenseQuery>;
export type GetAllSentenceSetsQueryResult = Apollo.QueryResult<GetAllSentenceSetsQuery, GetAllSentenceSetsQueryVariables>;
export const UpsertSentenceSetDocument = gql`
    mutation UpsertSentenceSet($input: SentenceSetUpsertInput!) {
  upsertSentenceSet(input: $input) {
    id
    name
    lang
    notes
    type
    meta
    isPublic
  }
}
    `;
export type UpsertSentenceSetMutationFn = Apollo.MutationFunction<UpsertSentenceSetMutation, UpsertSentenceSetMutationVariables>;

/**
 * __useUpsertSentenceSetMutation__
 *
 * To run a mutation, you first call `useUpsertSentenceSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSentenceSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSentenceSetMutation, { data, loading, error }] = useUpsertSentenceSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertSentenceSetMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSentenceSetMutation, UpsertSentenceSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSentenceSetMutation, UpsertSentenceSetMutationVariables>(UpsertSentenceSetDocument, options);
      }
export type UpsertSentenceSetMutationHookResult = ReturnType<typeof useUpsertSentenceSetMutation>;
export type UpsertSentenceSetMutationResult = Apollo.MutationResult<UpsertSentenceSetMutation>;
export type UpsertSentenceSetMutationOptions = Apollo.BaseMutationOptions<UpsertSentenceSetMutation, UpsertSentenceSetMutationVariables>;
export const DeleteSentenceSetDocument = gql`
    mutation DeleteSentenceSet($id: ID!) {
  deleteSentenceSet(id: $id)
}
    `;
export type DeleteSentenceSetMutationFn = Apollo.MutationFunction<DeleteSentenceSetMutation, DeleteSentenceSetMutationVariables>;

/**
 * __useDeleteSentenceSetMutation__
 *
 * To run a mutation, you first call `useDeleteSentenceSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSentenceSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSentenceSetMutation, { data, loading, error }] = useDeleteSentenceSetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSentenceSetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSentenceSetMutation, DeleteSentenceSetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSentenceSetMutation, DeleteSentenceSetMutationVariables>(DeleteSentenceSetDocument, options);
      }
export type DeleteSentenceSetMutationHookResult = ReturnType<typeof useDeleteSentenceSetMutation>;
export type DeleteSentenceSetMutationResult = Apollo.MutationResult<DeleteSentenceSetMutation>;
export type DeleteSentenceSetMutationOptions = Apollo.BaseMutationOptions<DeleteSentenceSetMutation, DeleteSentenceSetMutationVariables>;
export const UpdateSentenceSetOrderDocument = gql`
    mutation UpdateSentenceSetOrder($ids: [ID!]!) {
  updateSentenceSetOrder(ids: $ids)
}
    `;
export type UpdateSentenceSetOrderMutationFn = Apollo.MutationFunction<UpdateSentenceSetOrderMutation, UpdateSentenceSetOrderMutationVariables>;

/**
 * __useUpdateSentenceSetOrderMutation__
 *
 * To run a mutation, you first call `useUpdateSentenceSetOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSentenceSetOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSentenceSetOrderMutation, { data, loading, error }] = useUpdateSentenceSetOrderMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUpdateSentenceSetOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSentenceSetOrderMutation, UpdateSentenceSetOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSentenceSetOrderMutation, UpdateSentenceSetOrderMutationVariables>(UpdateSentenceSetOrderDocument, options);
      }
export type UpdateSentenceSetOrderMutationHookResult = ReturnType<typeof useUpdateSentenceSetOrderMutation>;
export type UpdateSentenceSetOrderMutationResult = Apollo.MutationResult<UpdateSentenceSetOrderMutation>;
export type UpdateSentenceSetOrderMutationOptions = Apollo.BaseMutationOptions<UpdateSentenceSetOrderMutation, UpdateSentenceSetOrderMutationVariables>;
export const SentencesWithSynonymsDocument = gql`
    query SentencesWithSynonyms($lang: String, $transLangs: [String]) {
  sentencesWithSynonyms(lang: $lang, transLangs: $transLangs) {
    id
    lang
    text
    type
    tags
    meta
    translations {
      id
      lang
      text
    }
    creator {
      id
      name
    }
  }
}
    `;

/**
 * __useSentencesWithSynonymsQuery__
 *
 * To run a query within a React component, call `useSentencesWithSynonymsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSentencesWithSynonymsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSentencesWithSynonymsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      transLangs: // value for 'transLangs'
 *   },
 * });
 */
export function useSentencesWithSynonymsQuery(baseOptions?: Apollo.QueryHookOptions<SentencesWithSynonymsQuery, SentencesWithSynonymsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SentencesWithSynonymsQuery, SentencesWithSynonymsQueryVariables>(SentencesWithSynonymsDocument, options);
      }
export function useSentencesWithSynonymsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SentencesWithSynonymsQuery, SentencesWithSynonymsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SentencesWithSynonymsQuery, SentencesWithSynonymsQueryVariables>(SentencesWithSynonymsDocument, options);
        }
export function useSentencesWithSynonymsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SentencesWithSynonymsQuery, SentencesWithSynonymsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SentencesWithSynonymsQuery, SentencesWithSynonymsQueryVariables>(SentencesWithSynonymsDocument, options);
        }
export type SentencesWithSynonymsQueryHookResult = ReturnType<typeof useSentencesWithSynonymsQuery>;
export type SentencesWithSynonymsLazyQueryHookResult = ReturnType<typeof useSentencesWithSynonymsLazyQuery>;
export type SentencesWithSynonymsSuspenseQueryHookResult = ReturnType<typeof useSentencesWithSynonymsSuspenseQuery>;
export type SentencesWithSynonymsQueryResult = Apollo.QueryResult<SentencesWithSynonymsQuery, SentencesWithSynonymsQueryVariables>;
export const DeleteSentenceDocument = gql`
    mutation DeleteSentence($input: DeleteSentenceInput!) {
  deleteSentence(input: $input)
}
    `;
export type DeleteSentenceMutationFn = Apollo.MutationFunction<DeleteSentenceMutation, DeleteSentenceMutationVariables>;

/**
 * __useDeleteSentenceMutation__
 *
 * To run a mutation, you first call `useDeleteSentenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSentenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSentenceMutation, { data, loading, error }] = useDeleteSentenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSentenceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSentenceMutation, DeleteSentenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSentenceMutation, DeleteSentenceMutationVariables>(DeleteSentenceDocument, options);
      }
export type DeleteSentenceMutationHookResult = ReturnType<typeof useDeleteSentenceMutation>;
export type DeleteSentenceMutationResult = Apollo.MutationResult<DeleteSentenceMutation>;
export type DeleteSentenceMutationOptions = Apollo.BaseMutationOptions<DeleteSentenceMutation, DeleteSentenceMutationVariables>;
export const DeleteMetaSynonymsDocument = gql`
    mutation DeleteMetaSynonyms($input: DeleteMetaSynonymsInput!) {
  deleteMetaSynonyms(input: $input)
}
    `;
export type DeleteMetaSynonymsMutationFn = Apollo.MutationFunction<DeleteMetaSynonymsMutation, DeleteMetaSynonymsMutationVariables>;

/**
 * __useDeleteMetaSynonymsMutation__
 *
 * To run a mutation, you first call `useDeleteMetaSynonymsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMetaSynonymsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMetaSynonymsMutation, { data, loading, error }] = useDeleteMetaSynonymsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMetaSynonymsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMetaSynonymsMutation, DeleteMetaSynonymsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMetaSynonymsMutation, DeleteMetaSynonymsMutationVariables>(DeleteMetaSynonymsDocument, options);
      }
export type DeleteMetaSynonymsMutationHookResult = ReturnType<typeof useDeleteMetaSynonymsMutation>;
export type DeleteMetaSynonymsMutationResult = Apollo.MutationResult<DeleteMetaSynonymsMutation>;
export type DeleteMetaSynonymsMutationOptions = Apollo.BaseMutationOptions<DeleteMetaSynonymsMutation, DeleteMetaSynonymsMutationVariables>;
export const AddMetaSentenceDocument = gql`
    mutation AddMetaSentence($inputs: [AddMetaInput!]!) {
  addMetaSentence(inputs: $inputs)
}
    `;
export type AddMetaSentenceMutationFn = Apollo.MutationFunction<AddMetaSentenceMutation, AddMetaSentenceMutationVariables>;

/**
 * __useAddMetaSentenceMutation__
 *
 * To run a mutation, you first call `useAddMetaSentenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMetaSentenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMetaSentenceMutation, { data, loading, error }] = useAddMetaSentenceMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useAddMetaSentenceMutation(baseOptions?: Apollo.MutationHookOptions<AddMetaSentenceMutation, AddMetaSentenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMetaSentenceMutation, AddMetaSentenceMutationVariables>(AddMetaSentenceDocument, options);
      }
export type AddMetaSentenceMutationHookResult = ReturnType<typeof useAddMetaSentenceMutation>;
export type AddMetaSentenceMutationResult = Apollo.MutationResult<AddMetaSentenceMutation>;
export type AddMetaSentenceMutationOptions = Apollo.BaseMutationOptions<AddMetaSentenceMutation, AddMetaSentenceMutationVariables>;
export const UpsertSentenceDocument = gql`
    mutation UpsertSentence($inputs: [SentenceUpsertInput!]!) {
  upsertSentence(inputs: $inputs)
}
    `;
export type UpsertSentenceMutationFn = Apollo.MutationFunction<UpsertSentenceMutation, UpsertSentenceMutationVariables>;

/**
 * __useUpsertSentenceMutation__
 *
 * To run a mutation, you first call `useUpsertSentenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSentenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSentenceMutation, { data, loading, error }] = useUpsertSentenceMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpsertSentenceMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSentenceMutation, UpsertSentenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSentenceMutation, UpsertSentenceMutationVariables>(UpsertSentenceDocument, options);
      }
export type UpsertSentenceMutationHookResult = ReturnType<typeof useUpsertSentenceMutation>;
export type UpsertSentenceMutationResult = Apollo.MutationResult<UpsertSentenceMutation>;
export type UpsertSentenceMutationOptions = Apollo.BaseMutationOptions<UpsertSentenceMutation, UpsertSentenceMutationVariables>;
export const UpsertTranslationsDocument = gql`
    mutation UpsertTranslations($inputs: [TranslationInput!]!) {
  upsertTranslations(inputs: $inputs)
}
    `;
export type UpsertTranslationsMutationFn = Apollo.MutationFunction<UpsertTranslationsMutation, UpsertTranslationsMutationVariables>;

/**
 * __useUpsertTranslationsMutation__
 *
 * To run a mutation, you first call `useUpsertTranslationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTranslationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTranslationsMutation, { data, loading, error }] = useUpsertTranslationsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpsertTranslationsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertTranslationsMutation, UpsertTranslationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertTranslationsMutation, UpsertTranslationsMutationVariables>(UpsertTranslationsDocument, options);
      }
export type UpsertTranslationsMutationHookResult = ReturnType<typeof useUpsertTranslationsMutation>;
export type UpsertTranslationsMutationResult = Apollo.MutationResult<UpsertTranslationsMutation>;
export type UpsertTranslationsMutationOptions = Apollo.BaseMutationOptions<UpsertTranslationsMutation, UpsertTranslationsMutationVariables>;
export const GetMissingVoicesDocument = gql`
    query getMissingVoices($setID: String!, $override: Boolean) {
  getMissingVoices(setID: $setID, override: $override) {
    id
    text
    lang
    meta
  }
}
    `;

/**
 * __useGetMissingVoicesQuery__
 *
 * To run a query within a React component, call `useGetMissingVoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissingVoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissingVoicesQuery({
 *   variables: {
 *      setID: // value for 'setID'
 *      override: // value for 'override'
 *   },
 * });
 */
export function useGetMissingVoicesQuery(baseOptions: Apollo.QueryHookOptions<GetMissingVoicesQuery, GetMissingVoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMissingVoicesQuery, GetMissingVoicesQueryVariables>(GetMissingVoicesDocument, options);
      }
export function useGetMissingVoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMissingVoicesQuery, GetMissingVoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMissingVoicesQuery, GetMissingVoicesQueryVariables>(GetMissingVoicesDocument, options);
        }
export function useGetMissingVoicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMissingVoicesQuery, GetMissingVoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMissingVoicesQuery, GetMissingVoicesQueryVariables>(GetMissingVoicesDocument, options);
        }
export type GetMissingVoicesQueryHookResult = ReturnType<typeof useGetMissingVoicesQuery>;
export type GetMissingVoicesLazyQueryHookResult = ReturnType<typeof useGetMissingVoicesLazyQuery>;
export type GetMissingVoicesSuspenseQueryHookResult = ReturnType<typeof useGetMissingVoicesSuspenseQuery>;
export type GetMissingVoicesQueryResult = Apollo.QueryResult<GetMissingVoicesQuery, GetMissingVoicesQueryVariables>;
export const SynthesizeVoiceDocument = gql`
    mutation SynthesizeVoice($input: SynthesizeVoiceInput!) {
  synthesizeVoice(input: $input) {
    success
  }
}
    `;
export type SynthesizeVoiceMutationFn = Apollo.MutationFunction<SynthesizeVoiceMutation, SynthesizeVoiceMutationVariables>;

/**
 * __useSynthesizeVoiceMutation__
 *
 * To run a mutation, you first call `useSynthesizeVoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSynthesizeVoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [synthesizeVoiceMutation, { data, loading, error }] = useSynthesizeVoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSynthesizeVoiceMutation(baseOptions?: Apollo.MutationHookOptions<SynthesizeVoiceMutation, SynthesizeVoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SynthesizeVoiceMutation, SynthesizeVoiceMutationVariables>(SynthesizeVoiceDocument, options);
      }
export type SynthesizeVoiceMutationHookResult = ReturnType<typeof useSynthesizeVoiceMutation>;
export type SynthesizeVoiceMutationResult = Apollo.MutationResult<SynthesizeVoiceMutation>;
export type SynthesizeVoiceMutationOptions = Apollo.BaseMutationOptions<SynthesizeVoiceMutation, SynthesizeVoiceMutationVariables>;
export const LoginDocument = gql`
    mutation Login($token: String!, $source: String!, $id: String) {
  login(token: $token, source: $source, id: $id) {
    id
    name
    email
    avatar
    role
    jwt
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      token: // value for 'token'
 *      source: // value for 'source'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;