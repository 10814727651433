"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.replaceWordsWithUnderscores = exports.scoreSentence = exports.normalizeText = void 0;
const equalize_1 = require("./equalize");
const uiDiff_1 = require("./uiDiff");
const normalizeText = (text) => {
    return text
        .normalize('NFD')
        .replace('’', "'")
        .replace('‘', "'")
        .replace('“', '"')
        .replace('”', '"')
        .trim()
        .toLowerCase()
        .replace(/[!.,;?]+$/, ''); // remove end punctuations;
};
exports.normalizeText = normalizeText;
const scoreSentence = (givenText, expectedText, time, meta, type) => {
    givenText = (0, exports.normalizeText)(givenText);
    expectedText = (0, exports.normalizeText)(expectedText);
    if (type === 'word' && meta.pos) {
        expectedText = (0, exports.replaceWordsWithUnderscores)(expectedText, meta.pos, true);
    }
    // Replace with the appropriate equalize function from your custom module
    const eq = (0, equalize_1.equalize)(expectedText, givenText);
    const diff = (0, uiDiff_1.uiDiff)(expectedText, givenText, meta.synonyms);
    let score;
    const totalWords = expectedText.trim().split(' ').length; // Total number of words that need to be guessed
    if (type === 'word' && meta.pos) {
        const errorsCount = diff.filter((obj) => obj.comp === 'added').length; // Count the number of errors
        // Calculate the correct words percentage
        score = Math.round(((totalWords - errorsCount) / totalWords) * 10);
        // Ensure the score is at least 0
        score = Math.max(score, 0);
    }
    else {
        score = Math.round(Math.min((diff.filter((obj) => obj.comp === 'same').length | 0) * (10.0 / totalWords), 10));
    }
    const errors = [];
    return { score: score, errors: errors, equalize: eq, time, diff };
};
exports.scoreSentence = scoreSentence;
const replaceWordsWithUnderscores = (text, pos, inverse = false) => {
    // Split the text into words
    const words = text.split(' ');
    // Check if lengths of words and POS tags array are the same
    if (words.length !== pos.length) {
        throw new Error('Length of text and POS array must be the same');
    }
    // Replace words with underscores if the corresponding POS tag contains 'S'
    const modifiedWords = words.map((word, index) => {
        if (pos[index].startsWith('S')) {
            if (inverse) {
                return word;
            }
            else {
                return '_'.repeat(word.length);
            }
        }
        else {
            if (inverse) {
                return '';
            }
            else
                ;
            {
                return word;
            }
        }
    });
    // Join the modified words into a single string and return
    return modifiedWords.filter((x) => x != '').join(' ');
};
exports.replaceWordsWithUnderscores = replaceWordsWithUnderscores;
