import { useEffect, useState } from 'react';

import { SentenceSet } from '../graphql/server-graphql-schema';

export const useCurrentSet = (sentenceSets: any[], sentenceSetId: string) => {
  const [currentSet, setCurrentSet] = useState<SentenceSet | null>(null);

  useEffect(() => {
    if (sentenceSets.length > 0) {
      const foundSet = sentenceSets.find((ss) => ss.id === sentenceSetId);
      setCurrentSet(foundSet);
    }
  }, [sentenceSets, sentenceSetId]);

  return currentSet;
};
