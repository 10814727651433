import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';

import { RootState, store } from '../store/store';

const API_URL = process.env.REACT_APP_API_URL || '';

const httpLink = new HttpLink({ uri: API_URL });

const authMiddleware = new ApolloLink((operation, forward) => {
  const state: RootState = store.getState();

  operation.setContext({
    headers: {
      Authorization: state.auth.auth.jwt,
    },
  });

  return forward(operation);
});

export const apolloClient = new ApolloClient({
  link: authMiddleware.concat(httpLink),
  cache: new InMemoryCache(),
});
