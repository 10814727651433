import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { FaPause, FaPlay, FaUndo } from 'react-icons/fa';

type Props = {
  onStart: () => void;
  onStop: () => void;
  onSecondsElapsedChange: (seconds: number) => void;
  secondsElapsed: number;
  activeButton: ActiveButtonType;
  setActiveButton: React.Dispatch<React.SetStateAction<ActiveButtonType>>;
};

export enum ActiveButtonType {
  START = 'START',
  STOP = 'STOP',
  NONE = 'NONE',
}

const TimerComponent: React.FC<Props> = ({ onStart, onStop, activeButton, setActiveButton, onSecondsElapsedChange }) => {
  const [secondsElapsed, setSecondsElapsed] = useState(0);

  useEffect(() => {
    let timer: any;
    if (activeButton === ActiveButtonType.START) {
      timer = setInterval(() => {
        setSecondsElapsed((prevTime) => {
          const newTime = prevTime + 1;

          // Defer the callback to avoid the warning
          setTimeout(() => {
            onSecondsElapsedChange(newTime); // Call the callback with the updated time
          }, 0);

          return newTime;
        });
      }, 1000);
    } else if (activeButton === ActiveButtonType.STOP || activeButton === ActiveButtonType.NONE) {
      clearInterval(timer);
    }

    // Handle ActiveButtonType.NONE state reset outside the interval
    if (activeButton === ActiveButtonType.NONE) {
      // Defer the callback to avoid the warning
      setTimeout(() => {
        onSecondsElapsedChange(0); // Call the callback with the updated time
      }, 0);
      setSecondsElapsed(0);
    }

    return () => clearInterval(timer);
  }, [activeButton, onSecondsElapsedChange]);

  useEffect(() => {
    if (activeButton === ActiveButtonType.NONE) {
      setSecondsElapsed(0);
    }
    return;
  }, [activeButton]);

  const handleStart = () => {
    setActiveButton(ActiveButtonType.START);
    onStart();
  };

  const handleStop = () => {
    setActiveButton(ActiveButtonType.STOP);
    onStop();
  };

  const handleReset = () => {
    setSecondsElapsed(0);
  };

  return (
    <div className="d-flex align-items-center">
      <div className="me-3">
        {Math.round((secondsElapsed / 60) * 10) / 10} Min<span className="d-none d-md-inline-block">utes</span>
      </div>

      <Button
        variant={activeButton === ActiveButtonType.START ? 'outline-primary' : 'outline-secondary'}
        onClick={handleStart}
        className="me-3"
      >
        <FaPlay />
      </Button>

      <Button
        variant={activeButton === ActiveButtonType.STOP ? 'outline-primary' : 'outline-secondary'}
        onClick={handleStop}
        className="me-3"
      >
        <FaPause />
      </Button>

      <Button variant={'outline-secondary'} onClick={handleReset} className="">
        <FaUndo />
      </Button>
    </div>
  );
};

export default TimerComponent;
