import React from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { clearAuth } from '../store/authSlice';

const LogoutButton: React.FC = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(clearAuth());
  };

  return (
    <Button variant="outline-primary" onClick={handleLogout}>
      <FontAwesomeIcon icon={solid('right-from-bracket')} className="me-" /> Logout
    </Button>
  );
};

export default LogoutButton;
