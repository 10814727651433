import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';

import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useSentenceSetSets from '../hooks/sentenceSetQueryHook';
import DownloadingWait from './DownloadingWait';

const SentenceSetList: React.FC = () => {
  const { loading, error, sentenceSets } = useSentenceSetSets();

  return (
    <div className="mt-4">
      {error && <p>Error: {error.message}</p>}
      {sentenceSets.length > 0 ? (
        <>
          <h4 className="mt-4">Choose an exercise set:</h4>
          <p className="fw-light">You can download the exercises and work on them offline.</p>

          <div className="mt-5">
            {sentenceSets.map((s: any) => (
              <div key={s.id} className="card mb-2">
                <Nav.Link
                  as={Link}
                  to={{
                    pathname: '/practice/' + s.id,
                  }}
                  className="mx-2"
                >
                  <div className="card-body d-flex justify-content-between align-items-center">
                    <div>
                      <div className="fw-bold">{s.name}</div>
                      <div className="mt-2 fw-light">
                        <span className="me-2">{s.notes}</span>
                        <span className="d-none d-md-inline-block">[{s.sentences?.length} Sentences]</span>
                      </div>
                    </div>

                    <div className="d-flex flex-row justify-content-between">
                      <FontAwesomeIcon icon={light('book-open-reader')} size="2x" />
                      {/*<FontAwesomeIcon
                        icon={light('spinner')}
                        size="2x"
                        style={{ display: loading ? 'inline-block' : 'none' }} // Hide if not loading
                        // className="mr-2"
                      />
                       <FontAwesomeIcon
                        icon={light('cloud-arrow-down')}
                        size="2x"
                        style={{ display: loading ? 'none' : 'inline-block' }} // Hide if loading
                      /> */}
                    </div>
                  </div>
                </Nav.Link>
              </div>
            ))}
          </div>
        </>
      ) : (
        <DownloadingWait />
      )}
    </div>
  );
};

export default SentenceSetList;
