import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web

import { configureStore } from '@reduxjs/toolkit';

import authReducer from './authSlice';
import sentenceReducer from './coreSlice';

const persistedAuthReducer = persistReducer(
  {
    key: 'auth',
    storage,
  },
  authReducer,
);

const persistedSentenceReducer = persistReducer(
  {
    key: 'core',
    storage,
  },
  sentenceReducer,
);

export const store = configureStore({
  reducer: { auth: persistedAuthReducer, core: persistedSentenceReducer },
});

export const persistore = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
