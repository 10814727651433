import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import SentenceSetList from '../components/SentenceSetList';
import { RootState } from '../store/store';

const SetManagementPage = () => {
  const auth = useSelector((state: RootState) => state.auth.auth);

  return (
    <div className="container mt-4 mb-5">
      <Helmet>
        <title>Manage your sets</title>
      </Helmet>

      {auth.email !== '' ? (
        <div className="row">
          <div className="col">
            <SentenceSetList />
          </div>
        </div>
      ) : (
        ' '
      )}
    </div>
  );
};

export default SetManagementPage;
