import { Helmet } from 'react-helmet-async';

import SynonymList from '../components/SynonymList';

const SynonymManagementPage = () => {
  return (
    <div className="container mt-4 mb-5">
      <Helmet>
        <title>Delete Synonyms</title>
      </Helmet>
      <h3>Delete Synonyms:</h3>

      <div className="row">
        <div className="col">
          <SynonymList />
        </div>
      </div>
    </div>
  );
};

export default SynonymManagementPage;
