import React from 'react';
import Button from 'react-bootstrap/Button';
import { FaTrashCan } from 'react-icons/fa6';

import {
  SynonymDeletionInput,
  useDeleteMetaSynonymsMutation,
  useSentencesWithSynonymsQuery,
} from '../graphql/server-graphql-schema';

interface Synonyms {
  [key: string]: string[];
}

const SynonymList: React.FC = () => {
  const [deleteMetaSynonymsMutation] = useDeleteMetaSynonymsMutation();
  const { loading, refetch, error, data } = useSentencesWithSynonymsQuery({
    variables: {
      lang: 'es-MX',
      transLangs: ['en-US'],
    },
  });

  const handleDelete = async (sentenceId: string, synonymsToDelete: SynonymDeletionInput[]) => {
    try {
      await deleteMetaSynonymsMutation({
        variables: {
          input: {
            id: sentenceId,
            synonyms: synonymsToDelete,
          },
        },
      });
      // Handle success response
      if (data) {
        await refetch();
        console.log('Synonyms deleted successfully');
      }
    } catch (e) {
      // Handle errors
      console.error('Error deleting synonyms', e);
    }
  };

  return (
    <div className="container mt-4">
      {data?.sentencesWithSynonyms && data.sentencesWithSynonyms.length > 0 ? (
        <div>
          {error && <div>{JSON.stringify(error)}</div>}
          <table className="table table-bordered table-sm table-striped">
            <thead>
              <tr>
                <th>Text</th>
                <th>Translation</th>
                <th>Synonym</th>
              </tr>
            </thead>
            <tbody>
              {data.sentencesWithSynonyms.map((s: any, index) => (
                <React.Fragment key={s.id + '-group-' + index}>
                  <tr key={s.id + index}>
                    <td className="align-middle">{s.text}</td>
                    <td className="align-middle">{s.translations.length > 0 ? s.translations[0].text : ''}</td>
                    {/* Assuming you want to keep the original cell that shows the synonyms object */}
                    <td className="align-middle"></td>
                  </tr>
                  {s.meta?.synonyms &&
                    Object.entries(s.meta.synonyms as Synonyms).map(([key, values], index) => (
                      <tr key={s.id + 'synonyms' + index}>
                        <td></td>
                        <td>
                          {key}
                          <Button variant="link" onClick={() => handleDelete(s.id, [{ key, valuesToDelete: [] }])}>
                            <FaTrashCan style={{ color: 'red' }} />
                          </Button>
                        </td>
                        <td colSpan={2}>
                          {values.map((v, index) => (
                            <Button
                              disabled={loading}
                              key={key + index}
                              onClick={() => handleDelete(s.id, [{ key, valuesToDelete: [v] }])}
                            >
                              {v}
                            </Button>
                          ))}
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No sentences have been added</p>
      )}
    </div>
  );
};

export default SynonymList;
