import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MINIMUM_PRACTICE_SCHEMA } from '../constants';
import { ACTION_PRACTICE } from './actionsPractice';
import { ACTION_SETTINGS } from './actionsSettings';

export interface Practice {
  schema: number;
  id: string;
  date: number;
  sentence: { id: string; lang: string; text: string; translations: { id: string; lang: string; text: string }[] };
  lang: string;
  text: string;
  tags: string;
  time: number;
  score: number;
  notes?: string;
  meta?: any;
}

export interface CoreState {
  practices: Practice[];
  practicesSynced: Practice[];
  shouldSyncPractice: boolean;
  settings: {
    defaultTransLang: string;
    transLangs: string[];
    lang: string;
  };
  lastPractice: {
    [key: string]: number;
  };
}

export const initialState: CoreState = {
  practices: [],
  practicesSynced: [],
  shouldSyncPractice: false,
  lastPractice: {},
  settings: {
    defaultTransLang: 'en-US',
    transLangs: ['en-US'],
    lang: 'es-MX',
  },
};

const sentenceSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    upgradeStore: (state) => {
      state.practices = state.practices.filter((p) => p.schema >= MINIMUM_PRACTICE_SCHEMA);
      state.practicesSynced = state.practicesSynced.filter((p) => p.schema >= MINIMUM_PRACTICE_SCHEMA);
      // state.practices = [];
      // state.practicesSynced = [];
    },
    setLastPractice: (
      state,
      action: PayloadAction<{
        [key: string]: number;
      }>,
    ) => {
      state.lastPractice = { ...state.lastPractice, ...action.payload };
    },

    ...ACTION_PRACTICE,
    ...ACTION_SETTINGS,
  },
});

export const { upgradeStore, setLastPractice, addPractice, deletePractices, addPracticesSynced, updateSettings } =
  sentenceSlice.actions;

export default sentenceSlice.reducer;
