import React from 'react';
import Button from 'react-bootstrap/Button';
import { FaEye, FaTrashCan } from 'react-icons/fa6';
import { useParams } from 'react-router-dom';

import { useDeleteSentenceMutation } from '../graphql/server-graphql-schema';
import { useCurrentSet } from '../hooks/currentSetHook';
import useSentenceSetSets from '../hooks/sentenceSetQueryHook';

const SentenceList: React.FC = () => {
  const { id: setId = 'set-id-not-provided' } = useParams();

  const { sentenceSets, loading, refetch } = useSentenceSetSets();
  const currentSet = useCurrentSet(sentenceSets, setId);

  const [deleteSentenceMutation] = useDeleteSentenceMutation();

  const handleDeleteSentence = async (sentenceId: string) => {
    try {
      await deleteSentenceMutation({
        fetchPolicy: 'network-only',
        variables: { input: { where: { id: sentenceId } } },
      });

      await refetch();
    } catch (error) {
      console.error('Error deleting:', error);
    }
  };

  if (loading) {
    return <div>Loading sentences</div>;
  }

  return (
    <div className="container mt-4">
      {currentSet?.sentences && currentSet.sentences.length > 0 ? (
        <div>
          <table className="table table-bordered table-sm table-striped">
            <thead>
              <tr className="text-center">
                <th>Text</th>
                <th>Translation</th>
                <th>Tags</th>
                <th>Meta</th>
                <th className="col-1">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentSet.sentences.map((s: any) => (
                <tr key={s.id}>
                  <td className="align-middle">{s.text}</td>
                  <td className="align-middle">{s.translations.length > 0 ? s.translations[0].text : ''}</td>
                  <td className="align-middle">{s.tags}</td>
                  <td className="align-middle">{JSON.stringify(s.meta)}</td>
                  <td className="align-middle">
                    <div className="d-flex flex-row justify-content-center">
                      <Button variant="link" className="mr-2">
                        <FaEye style={{ color: 'blue' }} />
                      </Button>
                      <Button variant="link" onClick={() => handleDeleteSentence(s.id)}>
                        <FaTrashCan style={{ color: 'red' }} />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No sentences have been added</p>
      )}
    </div>
  );
};

export default SentenceList;
