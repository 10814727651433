import { PayloadAction } from '@reduxjs/toolkit';

import { CoreState } from './coreSlice';

export const ACTION_SETTINGS = {
  updateSettings: (state: CoreState, action: PayloadAction<Partial<typeof state.settings>>) => {
    state.settings = {
      ...state.settings, // Preserve existing settings
      ...action.payload, // Overwrite settings with new values from action
    };
  },
};
