"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uiDiff = void 0;
const diff_1 = require("diff");
function areSynonyms(word1, word2, synonyms) {
    return synonyms[word2] && synonyms[word2].includes(word1);
}
const uiDiff = (text1, text2, synonyms) => {
    const diffResult = (0, diff_1.diffWords)(text2.normalize('NFC'), text1.normalize('NFC'), { ignoreWhitespace: true });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = [];
    for (let i = 0; i < diffResult.length; i++) {
        const part = diffResult[i];
        if (!part.added && !part.removed) {
            const words = part.value.trim().split(/\s+/);
            for (let j = 0; j < words.length; j++) {
                if (words[j].trim() !== '') {
                    result.push({ text: words[j].trim(), comp: 'same' });
                }
            }
            continue;
        }
        const words = part.value.trim().split(/\s+/);
        for (let j = 0; j < words.length; j++) {
            const word = words[j].trim();
            if (word !== '') {
                result.push({
                    text: word,
                    comp: part.added ? 'added' : part.removed ? 'removed' : 'same',
                });
            }
        }
    }
    // Reprocess the result array to handle synonyms
    const reprocessedResult = [];
    for (let i = 0; i < result.length; i++) {
        const current = result[i];
        const next = result[i + 1];
        if (synonyms && // if we have synonyms
            current.comp === 'removed' &&
            next &&
            next.comp === 'added' &&
            areSynonyms(current.text, next.text, synonyms)) {
            // Skip the next item, as we've handled it as a synonym
            i++;
            reprocessedResult.push({ text: current.text, comp: 'same' });
        }
        else {
            reprocessedResult.push(current);
        }
    }
    return reprocessedResult;
};
exports.uiDiff = uiDiff;
