import { useState } from 'react';
import { Spinner, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { LANG_MAP } from '../constants';
import { useGetMissingVoicesQuery, useSynthesizeVoiceMutation } from '../graphql/server-graphql-schema';
import { RootState } from '../store/store';

const VoiceManagementPage = () => {
  const { id: setId = 'set-id-not-provided' } = useParams();

  const initForm = {
    formLang: 'text',
  };

  const [result, setResult] = useState<any>(null);
  const [queryOverride, setQueryOverride] = useState(false);
  const [synthesizeOverride, setSynthesizeOverride] = useState(false);
  const [limit, setLimit] = useState(0);
  const auth = useSelector((state: RootState) => state.auth.auth);
  const [synthesizeVoice, { loading: loadingVoice }] = useSynthesizeVoiceMutation();
  const [formData, setFormData] = useState(initForm);

  const {
    data,
    loading: loadingData,
    refetch,
  } = useGetMissingVoicesQuery({
    variables: {
      setID: setId,
      override: queryOverride,
    },
    skip: auth.email === '',
  });

  const processUpload = async () => {
    if (!data?.getMissingVoices || data?.getMissingVoices.length === 0) {
      return;
    }

    const sentences = data.getMissingVoices.map(({ __typename, ...rest }) => rest);

    if (limit > 0) {
      sentences.splice(limit);
    }

    const res = await synthesizeVoice({
      fetchPolicy: 'network-only',
      variables: {
        input: {
          setID: setId,
          sentences,
          override: synthesizeOverride,
          lang: formData.formLang,
          type: formData.formLang === 'text' ? 'text' : 'translate',
        },
      },
    });
    setResult(res.data?.synthesizeVoice);

    await refetch(); // re-download the sentences sets
  };

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="container mt-4 mb-5">
      <Helmet>
        <title>Synthesize Voice</title>
      </Helmet>

      <h1 className="mb-4">Synthesize Voice</h1>
      <p className="mb-4">Get sentences that are missing voice file</p>

      <Form.Group as={Row} className="mb-2">
        <Col>
          <Form.Check
            type="checkbox"
            label="Get all sentences not just the ones missing voice"
            checked={queryOverride}
            onChange={(e) => setQueryOverride(e.target.checked)}
          />
          <Form.Check
            type="checkbox"
            label="Force regenerate voice files"
            checked={synthesizeOverride}
            onChange={(e) => setSynthesizeOverride(e.target.checked)}
          />
          <Form.Check
            type="checkbox"
            label="Test: Limit to 1 sentence"
            onChange={(e) => setLimit(e.target.checked ? 1 : 0)} // Set limit to 1 if checked, else reset to 0
          />
        </Col>
        <Col>
          <Form.Control as="select" id="formLang" name="formLang" value={formData.formLang} onChange={handleFormChange}>
            <option key={'text'} value={'text'}>
              ------ Original Language ------
            </option>
            {Object.entries(LANG_MAP).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col>
          <Button variant="primary" type="button" onClick={() => processUpload()}>
            {loadingData || loadingVoice ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Loading...
              </>
            ) : (
              'Generate Speech'
            )}
          </Button>
        </Col>
      </Form.Group>

      <div className="row">
        <div className="col">
          <pre>{result && JSON.stringify(result, null, 2)}</pre>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>ID</th>
                <th>Text</th>
                <th>Language</th>
                <th>Voices</th>
              </tr>
            </thead>
            <tbody>
              {data?.getMissingVoices.map((voice, index) => (
                <tr key={voice.id}>
                  <td>{index + 1}</td>
                  <td>{voice.id}</td>
                  <td>{voice.text}</td>
                  <td>{voice.lang}</td>
                  <td>{voice.meta.voiceGenerated?.join(' , ')}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default VoiceManagementPage;
