import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useParams } from 'react-router-dom';

import { LANG_MAP } from '../constants';
import {
  Sentence,
  useAddMetaSentenceMutation,
  useTranslateMutation,
  useUpsertTranslationsMutation,
} from '../graphql/server-graphql-schema';
import { useCurrentSet } from '../hooks/currentSetHook';
import useSentenceSetSets from '../hooks/sentenceSetQueryHook';

const TranslationForm: React.FC = () => {
  const { id: setId = 'set-id-not-provided' } = useParams();

  const initForm = {
    formLang: 'en-US',
    formSource: '',
    formTags: '',
    formTranslations: '',
    processType: 'POS',
  };

  const [formData, setFormData] = useState(initForm);
  const [errors, setErrors] = useState<any[]>([]);
  const [result, setResult] = useState<any[]>([]);
  const [sentencesToBeTranslated, setSentencesToBeTranslated] = useState<{
    [key: string]: Sentence;
  }>({});

  const { refetch, sentenceSets } = useSentenceSetSets();
  const currentSet = useCurrentSet(sentenceSets, setId);

  const [upsertTranslations, { loading: loadingUpload }] = useUpsertTranslationsMutation();
  const [translate, { loading: loadingTranslate }] = useTranslateMutation();
  const [addMetaSentenceMutation, { loading: loadingAddMeta }] = useAddMetaSentenceMutation();

  const handleTagSearch = async () => {
    if (!currentSet?.sentences) {
      return;
    }

    const sentences = currentSet?.sentences;

    let sourceLines = '';
    const tempSentences: {
      [key: string]: Sentence;
    } = {};

    for (let i = 0; i < sentences.length; i++) {
      const txt = sentences[i].text.trim();
      if (formData.formTags !== '') {
        if (
          txt &&
          sentences[i] &&
          typeof sentences[i].tags === 'string' &&
          formData.formTags &&
          sentences[i].tags!.indexOf(formData.formTags) > -1 && // Using ! to tell TypeScript this is non-null
          sentences[i].translations &&
          sentences[i].translations.length === 0
        ) {
          tempSentences[i] = sentences[i];
          sourceLines = sourceLines + `${i}>${txt}\n`;
        }
      } else {
        if (txt) {
          tempSentences[i] = sentences[i];
          sourceLines = sourceLines + `${i}>${txt}\n`;
        }
      }
    }
    setSentencesToBeTranslated(tempSentences);
    setFormData({ ...formData, formSource: sourceLines.trim() });
  };

  const processTranslation = async (data: Record<string, any>[]) => {
    const toUpload = [];

    for (const [key, values] of Object.entries(data)) {
      const sid = sentencesToBeTranslated[key].id;

      toUpload.push({ sentenceId: sid, meta: values.m, text: values.t.trim(), transLang: formData.formLang! });
    }

    const response = await upsertTranslations({
      fetchPolicy: 'network-only',
      variables: {
        inputs: toUpload,
      },
    });

    setResult((prevResult) => [...prevResult, response?.data?.upsertTranslations]);

    await refetch(); // re-download the sentences sets
  };

  const processPartOfSpeech = async (data: Record<string, any>[]) => {
    const toUpload = [];
    const errors = [];

    for (const [key, pos] of Object.entries(data)) {
      const sid = sentencesToBeTranslated[key].id;
      const text = sentencesToBeTranslated[key].text;

      if (pos.length !== text.trim().split(' ').length) {
        errors.push({ key, pos, text, sid });
      } else {
        toUpload.push({ id: sid, meta: { pos: pos } });
      }
    }

    const response = await addMetaSentenceMutation({
      fetchPolicy: 'network-only',
      variables: {
        inputs: toUpload,
      },
    });

    setErrors(errors);
    setResult((prevResult) => [...prevResult, response?.data?.addMetaSentence]);

    await refetch(); // re-download the sentences sets
  };

  const processMeta = async (data: Record<string, any>[]) => {
    const toUpload = [];

    for (const [key, meta] of Object.entries(data)) {
      const sid = sentencesToBeTranslated[key].id;

      const toUploadMeta: { hints?: typeof meta.hints; synonyms?: typeof meta.s } = {};

      if (meta.h) {
        toUploadMeta.hints = meta.h;
      }

      if (meta.s) {
        toUploadMeta.synonyms = meta.s;
      }

      if (meta.h || meta.s) {
        toUpload.push({ id: sid, meta: toUploadMeta });
      }
    }

    const response = await addMetaSentenceMutation({
      fetchPolicy: 'network-only',
      variables: {
        inputs: toUpload,
      },
    });

    setErrors(errors);
    setResult((prevResult) => [...prevResult, response?.data?.addMetaSentence]);

    await refetch(); // re-download the sentences sets
  };

  const handleProcessData = async () => {
    try {
      if (!formData.formTranslations || !formData.formLang) {
        setResult(['The translations field is empty.']);
      }
      const data: Record<string, any>[] = JSON.parse(formData.formTranslations.trim());

      if (formData.processType === 'POS') await processPartOfSpeech(data);
      if (formData.processType === 'T') await processTranslation(data);
      if (formData.processType === 'S/H') await processMeta(data);

      setFormData({ ...formData, formTranslations: '' });
    } catch (error) {
      console.error('Error creating sentence:', error);
    }
  };

  const handleAITranslate = async () => {
    const result = await translate({
      variables: {
        input: { values: { sentences: formData.formSource, lang: LANG_MAP[formData.formLang] }, name: 'tran' },
      },
    });

    if (result.data && Array.isArray(result.data.translate)) {
      formData.formTranslations = result.data.translate
        .map((c) => c.content)
        .join('')
        .trim();

      setResult((prevResult) => [
        ...prevResult,
        {
          ...result.data?.translate.map((c) => ({
            lang: formData.formLang,
            tokensUsed: c.tokensUsed,
            finishReason: c.finishReason,
          })),
        },
      ]);
    }
  };

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRefresh = async () => {
    await refetch();
  };

  return (
    <div className="mt-4">
      <h3>Add Translations:</h3>
      <Form className="border rounded bg-body-tertiary p-5">
        <h4 className="mb-4">{currentSet?.name}</h4>

        <Form.Group as={Row} className="mb-5">
          <Col sm={3}>
            <Form.Label htmlFor="formLang">Trans Language</Form.Label>
            <Form.Control as="select" id="formLang" name="formLang" value={formData.formLang} onChange={handleFormChange}>
              {Object.entries(LANG_MAP).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col sm={3}>
            <Form.Label htmlFor="formProcessType">Process Type</Form.Label>
            <Form.Control
              as="select"
              id="formProcessType"
              name="processType"
              value={formData.processType}
              onChange={handleFormChange}
            >
              <option value="T">Translation</option>
              <option value="POS">Part Of Speech</option>
              <option value="S/H">Synonyms / Hints</option>
            </Form.Control>
          </Col>
          <Col sm={6} className="d-flex justify-content-between align-items-end">
            <Button
              variant="outline-primary"
              type="button"
              disabled={formData.processType !== 'T'}
              className="btn"
              onClick={handleAITranslate}
            >
              {loadingTranslate ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Translating...
                </>
              ) : (
                'Translate'
              )}
            </Button>
            <Button variant="outline-primary" type="button" className="btn" onClick={handleRefresh}>
              Re-fetch Sets
            </Button>
            <Button variant="primary" type="button" className="btn" onClick={handleTagSearch}>
              Search and fill source
            </Button>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formTags" className="mb-2">
          <Form.Label column sm="2">
            Tags
          </Form.Label>
          <Col sm="4">
            <Form.Control
              type="text"
              name="formTags"
              autoComplete="off"
              onChange={handleFormChange}
              placeholder="Enter tags to search"
            />
          </Col>
          <Col sm="6" className="d-flex justify-content-end"></Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formSource" className="mb-2">
          <Form.Label column sm="2">
            Source Data
          </Form.Label>
          <Col sm="10">
            <Form.Control
              rows={10}
              as="textarea"
              name="formSource"
              autoComplete="off"
              value={formData.formSource}
              onChange={handleFormChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formTranslations" className="mb-2">
          <Form.Label column sm="2">
            Translations
          </Form.Label>
          <Col sm="10">
            <Form.Control
              rows={10}
              as="textarea"
              name="formTranslations"
              value={formData.formTranslations}
              onChange={handleFormChange}
              placeholder="Paste translated sentences here"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-2">
          <Col sm={{ span: 10, offset: 2 }}>
            <Button
              variant="primary"
              type="button"
              disabled={formData.formTranslations.trim().split('\n').length == 0}
              onClick={handleProcessData}
            >
              {loadingUpload || loadingAddMeta ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Uploading...
                </>
              ) : (
                'Upload ' + formData.processType
              )}
            </Button>
          </Col>
        </Form.Group>
      </Form>

      {result && (
        <div className="mt-4">
          <h3>Result</h3>
          <p>Created Item:</p>
          <pre>{JSON.stringify(result, null, 2)}</pre>
          <pre>{JSON.stringify(errors, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default TranslationForm;
