import React from 'react';

import { useDeleteFeedbackMutation, useGetAllUnprocessedFeedbacksQuery } from '../graphql/server-graphql-schema';

const FeedbackList: React.FC = () => {
  const [deleteFeedbackMutation] = useDeleteFeedbackMutation();
  const { loading, refetch, error, data } = useGetAllUnprocessedFeedbacksQuery();

  const handleDeleteFeedback = async (feedbackId: string) => {
    try {
      await deleteFeedbackMutation({
        variables: { id: feedbackId },
      });
      await refetch();
      console.log('Feedback deleted successfully');
    } catch (e) {
      console.error('Error deleting feedback', e);
    }
  };

  return (
    <div className="container mt-4">
      {data?.getAllUnprocessedFeedbacks && data.getAllUnprocessedFeedbacks.length > 0 ? (
        <div>
          {error && <div>{JSON.stringify(error)}</div>}
          <table className="table table-bordered table-sm table-striped">
            <thead>
              <tr>
                <th>Type</th>
                <th>Notes</th>
                <th>Data</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.getAllUnprocessedFeedbacks.map((f: any, index) => (
                <React.Fragment key={f.id + '-group-' + index}>
                  <tr key={f.id + index}>
                    <td className="align-middle">{f.type}</td>
                    <td className="align-middle">{f.notes}</td>
                    <td className="align-middle">{JSON.stringify(f.data)}</td>
                    <td>
                      <button onClick={() => handleDeleteFeedback(f.id)}>Delete</button>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No sentences have been added</p>
      )}
    </div>
  );
};

export default FeedbackList;
