import { PayloadAction } from '@reduxjs/toolkit';

import { CoreState, Practice } from './coreSlice';

export const ACTION_PRACTICE = {
  addPractice: (state: CoreState, action: PayloadAction<Practice>) => {
    const sortedPractices = [...state.practices, action.payload]
      .map((p) => {
        if (!p.date) {
          p.date = Math.floor(new Date().getTime() / 1000);
        }
        if (typeof p.date === 'string') {
          p.date = parseInt(p.date);
        }
        return p;
      })
      .sort((a, b) => b.date - a.date);
    state.practices = sortedPractices;

    // state.practices.unshift(action.payload);
  },
  deletePractices: (state: CoreState, action: PayloadAction<string | undefined>) => {
    if (action.payload) {
      state.practices = state.practices.filter((p) => p.id !== action.payload);
    } else {
      state.practices = [];
    }
  },
  addPracticesSynced: (state: CoreState, action: PayloadAction<Practice[]>) => {
    state.practicesSynced = action.payload;
  },
};
