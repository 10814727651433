import React from 'react';
import Container from 'react-bootstrap/Container';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { ApolloProvider } from '@apollo/client';
import { GoogleOAuthProvider } from '@react-oauth/google';

import NavBarComponent from './components/NavBarComponent';
import { apolloClient } from './graphql/ApolloClientSetup';
import PublicRoutes from './pages/routes/PublicRoutes';
import { persistore, store } from './store/store';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistore}>
          <ApolloProvider client={apolloClient}>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <Container fluid>
                <Router>
                  <NavBarComponent />
                  <PublicRoutes />
                </Router>
              </Container>
            </GoogleOAuthProvider>
          </ApolloProvider>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
