import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import useSentenceSetSets from '../hooks/sentenceSetQueryHook';

const DownloadingWait: React.FC = () => {
  const { loading } = useSentenceSetSets();

  return (
    <div style={{ display: 'grid', placeItems: 'center', height: '50vh' }}>
      <div className="d-flex flex-row justify-content-center align-items-center">
        <div className="me-3">Downloading exercises</div>
        <Spinner
          animation="border"
          style={{ display: loading ? 'inline-block' : 'none', width: '3rem', height: '3rem' }} // Hide if not loading
        />
      </div>
    </div>
  );
};

export default DownloadingWait;
