import { Helmet } from 'react-helmet-async';

import SentenceList from '../components/SentenceList';
import TranslationForm from '../components/TranslationForm';

const TranslationManagementPage = () => {
  return (
    <div className="container mt-4 mb-5">
      <Helmet>
        <title>Add Translations</title>
      </Helmet>

      <div className="row">
        <div className="col">
          <TranslationForm />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SentenceList />
        </div>
      </div>
    </div>
  );
};

export default TranslationManagementPage;
