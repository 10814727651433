import { Helmet } from 'react-helmet-async';

import FeedbackList from '../components/FeedbackList';

const FeedbackManagementPage = () => {
  return (
    <div className="container mt-4 mb-5">
      <Helmet>
        <title>Feedback Management</title>
      </Helmet>
      <h3>Feedback Management:</h3>

      <div className="row">
        <div className="col">
          <FeedbackList />
        </div>
      </div>
    </div>
  );
};

export default FeedbackManagementPage;
