import { Helmet } from 'react-helmet-async';

import SentenceForm from '../components/SentenceForm';
import SentenceList from '../components/SentenceList';

const SentenceManagementPage = () => {
  return (
    <div className="container mt-4 mb-5">
      <Helmet>
        <title>Add Sentence</title>
      </Helmet>

      <div className="row">
        <div className="col">
          <SentenceForm />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SentenceList />
        </div>
      </div>
    </div>
  );
};

export default SentenceManagementPage;
