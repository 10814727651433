import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import AdminSettingsForm from './AdminSettingsForm';

const AdminSettingsManagementPage: React.FC = () => {
  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <h2 className="mt-4">Admin Settings</h2>
          <AdminSettingsForm />
        </Col>
      </Row>
    </Container>
  );
};

export default AdminSettingsManagementPage;
