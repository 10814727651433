export const PRACTICE_SCHEMA = 2;
export const SENTENCE_SCHEMA = 1;
export const MINIMUM_PRACTICE_SCHEMA = 2;
export const AVAILABLE_LANGS = ['en-US', 'it-IT', 'ko-KO', 'fa-IR', 'zh-CN'];
export const LANG_MAP: Record<string, string> = {
  'en-US': 'English (US)',
  'it-IT': 'Italian',
  'ko-KO': 'Korean',
  'fa-IR': 'Farsi',
  'zh-CN': 'Chinese Simplified',
};
