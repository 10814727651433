import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import { Helmet } from 'react-helmet-async';
import { FaArrowRight } from 'react-icons/fa';
import { FaArrowLeft, FaCheck } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { normalizeText, scoreSentence } from '@ddehghan/lengua-shared';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DownloadingWait from '../components/DownloadingWait';
import TimerComponent, { ActiveButtonType } from '../components/TimerComponent';
import { PRACTICE_SCHEMA } from '../constants';
import { Sentence } from '../graphql/server-graphql-schema';
import { useCurrentSet } from '../hooks/currentSetHook';
import useSentenceSetSets from '../hooks/sentenceSetQueryHook';
import { addPractice, Practice, setLastPractice, updateSettings } from '../store/coreSlice';
import { RootState } from '../store/store';

interface FormData {
  practiceText: string;
}

const PracticePage: React.FC = () => {
  const auth = useSelector((state: RootState) => state.auth.auth);
  const practices = useSelector((state: RootState) => state.core.practices);
  const practicesSynced = useSelector((state: RootState) => state.core.practicesSynced);
  const lastPractice = useSelector((state: RootState) => state.core.lastPractice);
  const settings = useSelector((state: RootState) => state.core.settings);

  const [activeButton, setActiveButton] = useState<ActiveButtonType>(ActiveButtonType.NONE);
  const [practicesDone, setPracticesDone] = useState<Practice[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentSentence, setCurrentSentence] = useState<Sentence | undefined>();
  const [correctedText, setCorrectedText] = useState<any | null>(null);
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [translatedText, setTranslatedText] = useState('Not available ;(');

  const [formData, setFormData] = useState<FormData>({
    practiceText: '',
  });
  const { id: setId = 'set-id-not-provided' } = useParams();
  const [sentencesWithTranslation, setSentencesWithTranslation] = useState<Sentence[]>([]);

  const { sentenceSets } = useSentenceSetSets();
  const currentSet = useCurrentSet(sentenceSets, setId);

  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentIndex(lastPractice[setId] || 0);
  }, [lastPractice, setId]);

  useEffect(() => {
    if (currentSet?.sentences && currentSet.sentences.length > 0) {
      setSentencesWithTranslation(currentSet.sentences.filter((s) => s.translations && s.translations?.length > 0));
    }
  }, [currentSet]);

  useEffect(() => {
    setCurrentSentence(sentencesWithTranslation[currentIndex]);
  }, [sentencesWithTranslation, currentIndex]);

  useEffect(() => {
    const currentTranslations = currentSentence?.translations || [];
    const matchingTranslation = currentTranslations.find((translation) => translation.lang === settings.defaultTransLang);
    setTranslatedText(matchingTranslation?.text || 'Not available ;(');
  }, [currentSentence, settings.defaultTransLang]);

  useEffect(() => {
    setPracticesDone([...practicesSynced, ...practices].filter((p) => p.sentence.id === currentSentence?.id));
  }, [currentSentence, practicesSynced, practices]);

  const handleSecondsElapsedChange = (seconds: number) => {
    setSecondsElapsed(seconds);
  };

  const handleTimerStart = () => {
    setActiveButton(ActiveButtonType.START);
  };

  const handleTimerStop = () => {
    setActiveButton(ActiveButtonType.STOP);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    handleTimerStart(); // start timer as soon as the person types

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePrevious = () => {
    const i = currentIndex === 0 ? sentencesWithTranslation.length - 1 : currentIndex - 1;
    setCurrentIndex(i);
    dispatch(setLastPractice({ [setId]: i }));
    setCorrectedText(null);
    setActiveButton(ActiveButtonType.NONE);
  };

  const handleNext = () => {
    const i = currentIndex === sentencesWithTranslation.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(i);
    dispatch(setLastPractice({ [setId]: i }));
    setCorrectedText(null);
    setActiveButton(ActiveButtonType.NONE);
    setTimeout(handleTimerStart, 500); // needs a delay to let NONE make an effect
  };

  const verifyTextHandleChange = (e: any) => {
    e.preventDefault(); // Prevent the default form submission

    if (!currentSentence) {
      return;
    }

    setActiveButton(ActiveButtonType.NONE);

    const result = scoreSentence(formData.practiceText.trim(), currentSentence.text, secondsElapsed, {}, currentSentence.type);

    setCorrectedText(result);

    const practice = {
      schema: PRACTICE_SCHEMA,
      id: uuidv4(),
      date: Math.floor(new Date().getTime() / 1000),
      setId: setId,
      sentence: {
        id: currentSentence.id,
        lang: currentSentence.lang,
        text: currentSentence.text,
        translations: currentSentence.translations.map((t) => ({ id: t.id, lang: t.lang, text: t.text })),
      },
      lang: currentSentence.lang,
      text: formData.practiceText,
      time: result.time || 0,
      score: result.score,
      tags: '',
      meta: { equalize: result.equalize },
    };

    dispatch(addPractice(practice));

    setFormData({
      practiceText: '',
    });
  };

  const handleLanguageClick = (lang: string) => {
    dispatch(updateSettings({ defaultTransLang: lang }));
  };

  return (
    <div className="container">
      <Helmet>
        <title>Practice</title>
      </Helmet>

      {auth.email === '' || sentencesWithTranslation?.length < 1 ? (
        <DownloadingWait />
      ) : (
        <div>
          <div className="border rounded bg-body-tertiary justify-content-center mt-2 mx-n3 mx-md-0 p-2 p-md-5">
            <Form onSubmit={verifyTextHandleChange}>
              <div className="d-flex flex-column">
                <div>
                  <div className="d-flex justify-content-between mb-2">
                    <div className="align-self-center">
                      <div className="d-none d-md-inline-block me-2">Sentences:</div>
                      {currentIndex + 1} / {sentencesWithTranslation?.length}
                    </div>
                    <div className="d-none d-md-inline-block">
                      <TimerComponent
                        onStart={handleTimerStart}
                        onStop={handleTimerStop}
                        secondsElapsed={secondsElapsed}
                        activeButton={activeButton}
                        setActiveButton={setActiveButton}
                        onSecondsElapsedChange={handleSecondsElapsedChange}
                      />
                    </div>{' '}
                    <div>
                      <ButtonGroup>
                        {settings.transLangs.map((l) => (
                          <Button
                            variant="outline-secondary"
                            key={l}
                            onClick={() => handleLanguageClick(l)}
                            active={l === settings.defaultTransLang}
                          >
                            {l.split('-')[1]}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </div>
                  </div>
                  <div className="my-4 text-center fw-bold">{translatedText}</div>
                </div>
                <div className="my-3">
                  <Form.Control
                    rows={3}
                    as="textarea"
                    name="practiceText"
                    placeholder="Enter translation"
                    value={formData.practiceText}
                    onChange={handleInputChange}
                    onFocus={handleTimerStart}
                  />{' '}
                </div>

                <div className="d-flex justify-content-between mt-3">
                  <div className="">
                    <Button variant="outline-primary" onClick={handlePrevious}>
                      <FaArrowLeft />
                    </Button>
                  </div>
                  <Button className="mx-3" variant="outline-primary" type="submit">
                    <FaCheck /> Verify
                  </Button>
                  <div className="d-flex align-items-center">
                    <Button variant="outline-primary" onClick={handleNext}>
                      <FaArrowRight />
                    </Button>
                  </div>
                </div>

                {correctedText ? (
                  <div className="container my-3 p-3 rounded" style={{ backgroundColor: 'rgb(230, 230, 230)' }}>
                    <div className="row">
                      <>
                        <div className="col-md monospace-text">
                          <div className="mb-3">
                            <FontAwesomeIcon icon={solid('user-graduate')} className="me-3" width={20} />
                            {correctedText.equalize[0]}
                          </div>
                          <div>
                            <FontAwesomeIcon icon={light('user')} className="me-3" width={20} />
                            {correctedText.equalize[1]}
                          </div>
                        </div>
                        <div className="d-none d-md-block col-md-1 text-align-right">{correctedText.time} Sec</div>
                      </>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </Form>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <table className="table table-sm table-borderless mt-3">
              <thead>
                <tr>
                  <th className="py-2">
                    <FontAwesomeIcon icon={light('user')} className="me-1" /> My Answers
                  </th>
                  <th className="py-2 text-center">Date</th>
                </tr>
              </thead>
              <tbody>
                {practicesDone
                  .slice()
                  .reverse()
                  .map((p: Practice) => (
                    <tr key={p.id}>
                      <td className="align-middle">
                        {normalizeText(p.text) === normalizeText(p.sentence.text) ? (
                          <>
                            <FontAwesomeIcon icon={solid('check')} style={{ color: 'green' }} className="me-1" />
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={solid('xmark')} style={{ color: 'red' }} className="me-1" />
                          </>
                        )}
                        {p.text}
                      </td>
                      <td className="align-middle text-center">{moment.unix(p.date).fromNow()}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default PracticePage;
