import { useSelector } from 'react-redux';

import { useGetAllSentenceSetsQuery } from '../graphql/server-graphql-schema';
import { RootState } from '../store/store';

const useSentenceSetSets = () => {
  const auth = useSelector((state: RootState) => state.auth.auth);
  const settings = useSelector((state: RootState) => state.core.settings);

  const { loading, refetch, error, data } = useGetAllSentenceSetsQuery({
    variables: {
      lang: settings.lang,
      transLangs: settings.transLangs,
    },
    skip: auth.email === '',
  });

  return { loading, refetch, error, sentenceSets: data?.getAllSentenceSets || [] };
};

export default useSentenceSetSets;
