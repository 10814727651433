import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { SENTENCE_SCHEMA } from '../constants';
import { useUpsertSentenceMutation } from '../graphql/server-graphql-schema';
import useSentenceSetSets from '../hooks/sentenceSetQueryHook';
import { RootState } from '../store/store';

const SentenceForm: React.FC = () => {
  const { id: setId = 'set-id-not-provided' } = useParams();

  const settings = useSelector((state: RootState) => state.core.settings);
  const { loading, refetch } = useSentenceSetSets();

  const initForm = {
    lang: settings.lang,
    data: '',
    tags: '',
    type: 'sentence',
  };

  const [formData, setFormData] = useState(initForm);
  const [result, setResult] = useState<any[]>([]);

  const [upsertSentenceMutation, { loading: loadingUpload }] = useUpsertSentenceMutation();

  const handleCreateSentence = async () => {
    try {
      const lines = formData.data.trim().split('\n');
      console.log(lines);

      const toUpload = [];

      for (let index = 0; index < lines.length; index++) {
        const s = lines[index].trim();

        toUpload.push({
          schema: SENTENCE_SCHEMA,
          setId: setId,
          lang: formData.lang,
          tags: formData.tags,
          text: s,
          type: formData.type,
        });
      }

      const response = await upsertSentenceMutation({
        // fetchPolicy: 'network-only',
        variables: {
          inputs: toUpload,
        },
      });

      await refetch();

      setResult((prevResult) => [...prevResult, response?.data?.upsertSentence]);

      setFormData((prevData) => initForm);
    } catch (error) {
      console.error('Error creating sentence:', error);
    }
  };

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="container mt-4">
      <h3>Add Sentences: {}</h3>

      <Form className="border rounded bg-body-tertiary p-5">
        <Form.Group as={Row} controlId="formLang" className="mb-2">
          <Form.Label column sm="2">
            Language
          </Form.Label>
          <Col sm="4">
            <Form.Control
              type="text"
              name="lang"
              value={formData.lang}
              onChange={handleFormChange}
              placeholder="Enter language"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formType" className="mb-2">
          <Form.Label column sm="2">
            Type
          </Form.Label>
          <Col sm="4">
            <Form.Control as="select" name="type" value={formData.type} onChange={handleFormChange}>
              <option value="sentence">Sentence</option>
              <option value="word">Word</option>
            </Form.Control>
          </Col>
        </Form.Group>{' '}
        <Form.Group as={Row} controlId="formTag" className="mb-2">
          <Form.Label column sm="2">
            Tags
          </Form.Label>
          <Col sm="4">
            <Form.Control type="text" name="tags" value={formData.tags} onChange={handleFormChange} placeholder="" />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formData" className="mb-2">
          <Form.Label column sm="2">
            Data
          </Form.Label>
          <Col sm="10">
            <Form.Control
              rows={10}
              as="textarea"
              name="data"
              value={formData.data}
              onChange={handleFormChange}
              placeholder="Enter data"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-2">
          <Col sm={{ span: 10, offset: 2 }}>
            <Button variant="primary" type="button" onClick={handleCreateSentence}>
              {loadingUpload ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Uploading...
                </>
              ) : (
                'Upload Sentences'
              )}
            </Button>
          </Col>
        </Form.Group>
      </Form>

      {result && (
        <div className="mt-4">
          <h3>Result</h3>
          <p>Created Item:</p>
          <pre>{JSON.stringify(result, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default SentenceForm;
